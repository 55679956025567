import { useSearchParams } from '@solidjs/router';
import dayjs from '@troon/dayjs';
import { createContext, createEffect, createSignal, useContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import { withScope } from '@sentry/solidstart';
import type { Accessor, ParentProps } from 'solid-js';

const keys = ['campaign', 'source', 'medium', 'term', 'content'] as const;

type UTMKey = (typeof keys)[number];

type Params = Record<UTMKey, string | undefined>;

const storageKey = 'utm';

const Ctx = createContext<{ utm: Accessor<Params> }>({ utm: () => ({}) as Params });

/**
 * Non-reactive source-param tracker with saving to localstorage
 */
export function UtmProvider(props: ParentProps) {
	const [search] = useSearchParams();
	const [utm, setUtm] = createSignal<Params>(getUTM(search).params);

	createEffect(() => {
		if (isServer) {
			return;
		}

		const { params, hasUTM } = getUTM(search);

		try {
			if (hasUTM) {
				setUtm(params);
				localStorage.setItem(storageKey, JSON.stringify({ ...params, created: Date.now() }));
			}
		} catch (e) {
			withScope((scope) => {
				scope.setLevel('warning');
				scope.captureException(e);
			});
		}

		// localstorage is known to be finicky. guard and don't worry about fallback for now
		try {
			const storage = localStorage.getItem(storageKey);
			if (storage) {
				const { created, ...stored } = JSON.parse(storage) as Params & { created: number };
				const ttlMet = dayjs().isAfter(dayjs(created).add(1, 'hour'));
				if (stored && !ttlMet) {
					setUtm(stored);
				} else if (ttlMet) {
					// Delete the stored source after 1 hour
					localStorage.removeItem('utm');
				}
			}
		} catch (e) {
			withScope((scope) => {
				scope.setLevel('warning');
				scope.captureException(e);
			});
		}
	});

	return <Ctx.Provider value={{ utm }}>{props.children}</Ctx.Provider>;
}

export function useUtmParams() {
	const ctx = useContext(Ctx);
	return ctx.utm;
}

const defaultParams: Params = {
	campaign: undefined,
	source: undefined,
	medium: undefined,
	term: undefined,
	content: undefined,
};

function getUTM(input: Partial<Params>) {
	const params = { ...defaultParams };
	let hasUTM = false;
	for (const [key, val] of Object.entries(input)) {
		if (key.startsWith('utm_') && keys.find((utmKey) => utmKey === key.replace(/^utm_/, ''))) {
			hasUTM = true;
			params[key.replace(/^utm_/, '') as UTMKey] = val;
		}
	}
	return { params, hasUTM };
}
